import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { LayoutHelper } from 'olx-autos-landing-page';
import withTrack from 'HOCs/withTrack/withTrack';
import { addFilterPreference } from 'Actions/filtersPreferences';
import { updateLocation } from 'Actions/selectLocation';
import {
    getPopularLocationsLeftBar,
    getCurrentListingLocation } from 'Selectors/popularLocations';
import { getFiltersPreference } from 'Selectors/filtersPreferences';
import { FILTER_TOP_LOCATIONS } from 'Constants/locations';
import Collapsible from 'Components/Collapsible/Collapsible';
import TopLocationsModal from './TopLocationsModal';
import css from './TopLocations.scss';
import { LOCATION_COMPLETE, FILTER, TYPE_TREE } from 'Constants/tracking';
import LocationGroup from './LocationGroup';
import { getCategoriesForTracking } from 'Selectors/categories';
import { getCategoryTree } from 'Reducers/componentHelpers';

export class TopLocations extends React.Component {
    static propTypes = {
        locations: PropTypes.array.isRequired,
        currentLocation: PropTypes.shape({
            name: PropTypes.string,
            id: PropTypes.number
        }),
        track: PropTypes.func,
        collapsed: PropTypes.bool,
        onCollapsed: PropTypes.func,
        updateLocation: PropTypes.func,
        onLocationChange: PropTypes.func,
        type: PropTypes.string,
        intl: PropTypes.shape({
            formatMessage: PropTypes.func.isRequired
        }).isRequired,
        isFiltersV2: PropTypes.bool,
        params: PropTypes.shape({
            categoryID: PropTypes.string.isRequired
        }).isRequired,
        categoryTreeSelected: PropTypes.object
    };

    static defaultProps = {
        collapsed: false,
        onCollapsed: () => {},
        updateLocation: () => {},
        track: () => {},
        currentLocation: {
            name: '',
            id: 0
        },
        onLocationChange: () => {},
        type: ''
    };

    constructor(props) {
        super(props);

        this.traslations = {
            viewAll: { id: 'viewAll' },
            locations: { id: 'locations' }
        };

        this.state = {
            collapsed: props.collapsed,
            showModal: false
        };
    }

    onCollapsed = () => {
        this.setState({
            collapsed: !this.state.collapsed
        }, () => this.props.onCollapsed(this.state.collapsed));
    };

    onViewAll = () => this.setState({ showModal: true });

    onCloseModal = () => this.setState({ showModal: false });

    onClickLocation = (_, location) => {
        const categoryTree = getCategoriesForTracking(this.props.categoryTreeSelected);

        this.props.track(LOCATION_COMPLETE, {
            place_selected_id: location.id,
            select_from: FILTER,
            location_type: TYPE_TREE,
            ...LayoutHelper.getAcquisitionChannelTrackingValues(),
            ...categoryTree
        });

        this.props.updateLocation(location.id);
        this.props.onLocationChange(location);
    };

    render() {
        const { locations, currentLocation: { name }, intl, isFiltersV2, params } = this.props;
        const { collapsed, showModal } = this.state;

        return (
            <Collapsible
                className={ classnames(css.topLocations, { [css.noSidePadding]: isFiltersV2 }) }
                title={ intl.formatMessage(this.traslations.locations) }
                collapsedContent={ name }
                collapsed={ collapsed }
                onCollapsed={ this.onCollapsed }
                data-aut-id="collapsible_topLocations"
                isFiltersV2={ isFiltersV2 }
            >
                <React.Fragment>
                    <LocationGroup
                        items={ locations }
                        onSelect={ this.onClickLocation }
                        onViewAll={ this.onViewAll }
                        isFiltersV2={ isFiltersV2 }
                        category={ params?.categoryID } />
                    {
                        showModal && <TopLocationsModal
                            onClose={ this.onCloseModal }
                            onSelect={ this.onClickLocation }
                            type={ this.props.type }
                        /> || null
                    }
                </React.Fragment>
            </Collapsible>
        );
    }
}

const getParams = props => ({
    categoryId: props.params.categoryID,
    locationId: props.params.geoID,
    search: props.params.text,
    params: props.location.query,
    constructionStatus: props.params.constructionStatus,
    type: props.type || ''
});

const mapStateToProps = (state, ownProps) => ({
    locations: getPopularLocationsLeftBar(state, getParams(ownProps)),
    currentLocation: getCurrentListingLocation(state, getParams(ownProps)),
    collapsed: getFiltersPreference(state, { key: FILTER_TOP_LOCATIONS }),
    categoryTreeSelected: getCategoryTree(
        state?.categories?.elements,
        ownProps?.categoryId
    )
});

const mapDispatchToProps = dispatch => ({
    onCollapsed: collapsed => dispatch(addFilterPreference({ [FILTER_TOP_LOCATIONS]: collapsed })),
    updateLocation: id => dispatch(updateLocation({ id }))
});

const decorateComponent = compose(
    withRouter,
    withTrack,
    injectIntl,
    connect(mapStateToProps, mapDispatchToProps)
);

export default decorateComponent(TopLocations);
