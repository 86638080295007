/**
 * It is called dynamically from buildSchema()
 * @param props
 * @returns {{contactPoint: [{'@type': string, telephone: (string|*), contactType: (string|*), email}], '@type': string, potentialAction: {'query-input': string, '@type': string, target: (string|*)}, name, logo, description, inLanguage: (string|*), '@context': string, url: *, sameAs: (string[]|string[]|*)}}
 */
const buildWebPage = props => {
    const SEO = props.marketConfig.get('SEO');
    const { category, item } = props;

    const name = item?.title && category?.name && item?.id ? `${ item.title } - ${category.name} - ${ item.id }` : props?.seoTitle || SEO.defaultTitle;
    const description = item?.description && category?.name ? `${ item.description } - ${category.name}` : props?.seoDescription || SEO.defaultDescription;

    return {
        '@context': 'https://schema.org',
        '@type': 'WebPage',
        name,
        description,
        publisher: {
            '@type': 'Organization',
            name: 'OLX India'
        }
    };
};

export default buildWebPage;
