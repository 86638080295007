import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Link } from 'panamera-react-ui';
import css from './ItemLocation.scss';
import { formatPrice } from 'Helpers/numbers';
import { connect } from 'react-redux';
import { getLocale } from 'Selectors/config';
import { displayLocationTitleFor, locationTitleText } from './constants';

export const ItemLocation = ({ item, onClick, isFiltersV2, locale, category }) => {
    const { title, to, showCount, count, selected, list = [] } = item;
    const onClickElem = useCallback(event => onClick(event, item), [item, onClick]);

    const getLocationTitle = (location, categoryId) => {
        if (displayLocationTitleFor.includes(categoryId)) {
            const titleTemplate = locationTitleText.find(item => item[categoryId]);

            if (titleTemplate) {
                const title = titleTemplate[categoryId].replace('{location}', location);

                return title;
            }
        }
        return '';
    };

    return (
        <Link
            to={ to }
            className={ classnames(css.link,
                {
                    [css.tanakLink]: isFiltersV2,
                    [css.selected]: selected,
                    [css.tanakSelected]: selected && isFiltersV2,
                    [css.lastItemGroup]: !list.length && isFiltersV2
                }) }
            data-aut-id={ `location_${title}` }
            onClick={ onClickElem }
            title={ getLocationTitle(title, category) }>
            { list.length && isFiltersV2
                ? <div className={ css.container }>
                    <div className={ css.hyphen } />
                    <div>{title}</div>
                </div>
                : title
            }
            { showCount && <span>{` (${isFiltersV2 ? formatPrice(count, locale) : count})`}</span> || null }
        </Link>
    );
};

ItemLocation.propTypes = {
    item: PropTypes.object.isRequired,
    onClick: PropTypes.func.isRequired,
    isFiltersV2: PropTypes.bool,
    locale: PropTypes.string.isRequired,
    category: PropTypes.string
};

export const mapStateToProps = state => ({
    locale: getLocale(state)
});

export default connect(mapStateToProps)(ItemLocation);
