export const InlineWidgetDesktopOffset = 12;
export const InlineWidgetMobileOffset = 5;
export const MinimumItemToShowInlineWidgetDesktop = 15;
export const MinimumItemToShowInlineWidgetMobile = 6;

export const locations = {
    4058659: [
        {
            id: 4058748,
            name: 'Gurgaon',
            type: 'CITY',
            longitude: 76.99699,
            latitude: 28.42099,
            parentId: 2001155
        },
        {
            id: 4059326,
            name: 'Noida',
            type: 'CITY',
            longitude: 77.37036,
            latitude: 28.53612,
            parentId: 2001176
        },
        {
            id: 4059280,
            name: 'Ghaziabad',
            type: 'CITY',
            longitude: 77.43316,
            latitude: 28.6788,
            parentId: 2001176
        },
        {
            id: 4387779,
            name: 'Faridabad',
            type: 'CITY',
            longitude: 77.37718,
            latitude: 28.28152,
            parentId: 2001155
        },
        {
            id: 4059314,
            name: 'Meerut',
            type: 'CITY',
            longitude: 77.70593,
            latitude: 28.9799,
            parentId: 2001176
        }
    ],
    4058748: [
        {
            id: 4059326,
            name: 'Noida',
            type: 'CITY',
            longitude: 77.37036,
            latitude: 28.53612,
            parentId: 2001176
        },
        {
            id: 4059280,
            name: 'Ghaziabad',
            type: 'CITY',
            longitude: 77.43316,
            latitude: 28.6788,
            parentId: 2001176
        },
        {
            id: 4387779,
            name: 'Faridabad',
            type: 'CITY',
            longitude: 77.37718,
            latitude: 28.28152,
            parentId: 2001155
        },
        {
            id: 4059314,
            name: 'Meerut',
            type: 'CITY',
            longitude: 77.70593,
            latitude: 28.9799,
            parentId: 2001176
        },
        {
            id: 4058659,
            name: 'Delhi',
            type: 'CITY',
            longitude: 77.08626,
            latitude: 28.66664,
            parentId: 2001152
        }
    ],
    4059326: [
        {
            id: 4058748,
            name: 'Gurgaon',
            type: 'CITY',
            longitude: 76.99699,
            latitude: 28.42099,
            parentId: 2001155
        },
        {
            id: 4058659,
            name: 'Delhi',
            type: 'CITY',
            longitude: 77.08626,
            latitude: 28.66664,
            parentId: 2001152
        },
        {
            id: 4059280,
            name: 'Ghaziabad',
            type: 'CITY',
            longitude: 77.43316,
            latitude: 28.6788,
            parentId: 2001176
        },
        {
            id: 4387779,
            name: 'Faridabad',
            type: 'CITY',
            longitude: 77.37718,
            latitude: 28.28152,
            parentId: 2001155
        },
        {
            id: 4059314,
            name: 'Meerut',
            type: 'CITY',
            longitude: 77.70593,
            latitude: 28.9799,
            parentId: 2001176
        }
    ],
    4058803: [
        {
            id: 4058841,
            name: 'Mysuru',
            type: 'CITY',
            longitude: 76.64278,
            latitude: 12.33148,
            parentId: 2001159
        },
        {
            id: 4058856,
            name: 'Tumakuru',
            type: 'CITY',
            longitude: 77.10568,
            latitude: 13.33591,
            parentId: 2001159
        },
        {
            id: 4058840,
            name: 'Mangaluru',
            type: 'CITY',
            longitude: 74.85973,
            latitude: 12.91722,
            parentId: 2001159
        },
        {
            id: 4059174,
            name: 'Hosur',
            type: 'CITY',
            longitude: 77.82975,
            latitude: 12.73823,
            parentId: 2001173
        },
        {
            id: 4058526,
            name: 'Hyderabad',
            type: 'CITY',
            longitude: 78.43517,
            latitude: 17.46497,
            parentId: 2007599
        }
    ],
    4058526: [
        {
            id: 4058576,
            name: 'Secunderabad',
            type: 'CITY',
            longitude: 78.50183,
            latitude: 17.43984,
            parentId: 2007599
        },
        {
            id: 4058596,
            name: 'Warangal',
            type: 'CITY',
            longitude: 79.60928,
            latitude: 17.95891,
            parentId: 2007599
        },
        {
            id: 4058593,
            name: 'Visakhapatnam',
            type: 'CITY',
            longitude: 83.2657,
            latitude: 17.73233,
            parentId: 2001145
        },
        {
            id: 4058535,
            name: 'Karimnagar',
            type: 'CITY',
            longitude: 79.13015,
            latitude: 18.43324,
            parentId: 2007599
        },
        {
            id: 4058591,
            name: 'Vijayawada',
            type: 'CITY',
            longitude: 80.64038,
            latitude: 16.50512,
            parentId: 2001145
        }
    ],
    4059162: [
        {
            id: 4059067,
            name: 'Puducherry',
            type: 'CITY',
            longitude: 79.81716,
            latitude: 11.93657,
            parentId: 2001169
        },
        {
            id: 4058556,
            name: 'Nellore',
            type: 'CITY',
            longitude: 79.98288,
            latitude: 14.42934,
            parentId: 2001145
        },
        {
            id: 4059231,
            name: 'Vellore',
            type: 'CITY',
            longitude: 79.13663,
            latitude: 12.89972,
            parentId: 2001173
        },
        {
            id: 4059164,
            name: 'Coimbatore',
            type: 'CITY',
            longitude: 76.97483,
            latitude: 11.04699,
            parentId: 2001173
        },
        {
            id: 4058526,
            name: 'Hyderabad',
            type: 'CITY',
            longitude: 78.43517,
            latitude: 17.46497,
            parentId: 2007599
        }
    ],
    4058873: [
        {
            id: 4058889,
            name: 'Thiruvananthapuram',
            type: 'CITY',
            longitude: 76.92198,
            latitude: 8.49521,
            parentId: 2001160
        },
        {
            id: 4058877,
            name: 'Kozhikode',
            type: 'CITY',
            longitude: 75.78396,
            latitude: 11.25338,
            parentId: 2001160
        },
        {
            id: 4058876,
            name: 'Kottayam',
            type: 'CITY',
            longitude: 76.5218,
            latitude: 9.58645,
            parentId: 2001160
        },
        {
            id: 4058891,
            name: 'Thrissur',
            type: 'CITY',
            longitude: 76.20156,
            latitude: 10.51731,
            parentId: 2001160
        },
        {
            id: 4059164,
            name: 'Coimbatore',
            type: 'CITY',
            longitude: 76.97483,
            latitude: 11.04699,
            parentId: 2001173
        }
    ],
    4059014: [
        {
            id: 4058997,
            name: 'Mumbai',
            type: 'CITY',
            longitude: 72.8605,
            latitude: 19.0591,
            parentId: 2001163
        },
        {
            id: 4059026,
            name: 'Thane',
            type: 'CITY',
            longitude: 72.99396,
            latitude: 19.21336,
            parentId: 2001163
        },
        {
            id: 4059005,
            name: 'Navi Mumbai',
            type: 'CITY',
            longitude: 73.06137,
            latitude: 18.9685,
            parentId: 2001163
        },
        {
            id: 4058998,
            name: 'Nagpur',
            type: 'CITY',
            longitude: 79.08911,
            latitude: 21.15576,
            parentId: 2001163
        },
        {
            id: 4059021,
            name: 'Aurangabad',
            type: 'CITY',
            longitude: 75.31039,
            latitude: 19.85979,
            parentId: 2001163
        }
    ],
    4058997: [
        {
            id: 4059014,
            name: 'Pune',
            type: 'CITY',
            longitude: 73.85799,
            latitude: 18.53121,
            parentId: 2001163
        },
        {
            id: 4059026,
            name: 'Thane',
            type: 'CITY',
            longitude: 72.99396,
            latitude: 19.21336,
            parentId: 2001163
        },
        {
            id: 4059005,
            name: 'Navi Mumbai',
            type: 'CITY',
            longitude: 73.06137,
            latitude: 18.9685,
            parentId: 2001163
        },
        {
            id: 4059002,
            name: 'Nashik',
            type: 'CITY',
            longitude: 73.78394,
            latitude: 20.00066,
            parentId: 2001163
        },
        {
            id: 4058998,
            name: 'Nagpur',
            type: 'CITY',
            longitude: 79.08911,
            latitude: 21.15576,
            parentId: 2001163
        }
    ]
};
