/* HOC to render the Schema component for a specific module in a React component */

import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';
import JsonLDSchema from 'Components/SEO/Microdata/Schemas/JsonLDSchema';
import { connect } from 'react-redux';
import { compose } from 'redux';

export const withSchema = schema => WrappedComponent => {
    const WithSchema = props => {
        const schemaType = schema || WrappedComponent.name || 'WebSite';

        return (
            <>
                <JsonLDSchema type={ schemaType } { ...props } />
                <WrappedComponent { ...props } />
            </>
        );
    };

    WithSchema.displayName = `withSchema(${WrappedComponent.displayName || WrappedComponent.name})`;

    const mapStateToProps = state => ({
        categories: state?.categories
    });

    const connectedComponent = compose(connect(mapStateToProps))(WithSchema);

    return hoistNonReactStatic(connectedComponent, WrappedComponent);
};
