export const PREFIX_AUT_ID = 'ulLevel_';
export const MAX_ITEMS_SHOW = 5;

export const displayLocationTitleFor = ['84'];

export const locationTitleText = [
    {
        84: 'Used Cars in {location}'
    }
];
