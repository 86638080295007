import { MAIN_CATEGORY, MAIN_CATEGORY_V1 } from './categories';

export const QUESTION_SEO = {
    MAIN_ENTITY: 'mainEntity',
    ITEM_TYPE: 'https://schema.org/Question',
    ITEM_PROP: 'name'
};

export const ANSWER_SEO = {
    ITEM_TYPE: 'https://schema.org/Answer',
    ITEM_PROP: 'acceptedAnswer'
};

export const MAKE_KEY = 'make';

const commonContentType = {
    CATEGORY_L1_LOCATION_L3: 'category_l1_location_l3',
    CATEGORY_L2_LOCATION_L3: 'category_l2_location_l3'
};

const GENERAL = 'general';
const LOCATION_L1 = 'location_l1';
const LOCATION_L3 = 'location_l3';
const LOCATION_L3_FURNISHING = 'location_l3_furnishing';
const LOCATION_L3_BRAND = 'location_l3_brand';

const appendLocationToCategory = params => {
    const result = {};
    const keys = Object.keys(params);

    keys.forEach(item => {
        const categoryIds = params[item];

        categoryIds.forEach(categoryId => {
            result[categoryId] = {
                ...result[categoryId],
                [item]: item.toLowerCase()

            };
        });
    });
    return result;
};

const categoriesForL3 = [
    MAIN_CATEGORY_V1.COMMERCIAL_VEHICLES, MAIN_CATEGORY_V1.COMMERCIAL_VEHICLES_SPARE_PARTS,
    MAIN_CATEGORY_V1.BOOKS, MAIN_CATEGORY_V1.GYM_FITNESS_EQUIPMENTS, MAIN_CATEGORY_V1.MUSICAL_INSTRUMENTS,
    MAIN_CATEGORY_V1.SPORTS_EQUIPMENTS, MAIN_CATEGORY_V1.SERVICES, MAIN_CATEGORY_V1.EDUCATION_AND_CLASSES,
    MAIN_CATEGORY_V1.ELECTRONICS_REPAIR_AND_SERVICES, MAIN_CATEGORY_V1.HEALTH_AND_BEAUTY,
    MAIN_CATEGORY_V1.HOME_RENOVATION_AND_REPAIR, MAIN_CATEGORY_V1.CLEANING_AND_PEST_CONTROL,
    MAIN_CATEGORY_V1.LEGAL_AND_DOCUMENTATION_SERVICES, MAIN_CATEGORY_V1.PACKERS_AND_MOVERS,
    MAIN_CATEGORY_V1.OTHER_SERVICES, MAIN_CATEGORY_V1.FURNITURE, MAIN_CATEGORY_V1.SOFA_DINNING,
    MAIN_CATEGORY_V1.BEDS_WARDROBES, MAIN_CATEGORY_V1.HOME_DECOR_GARDEN, MAIN_CATEGORY_V1.KIDS_FURNITURE,
    MAIN_CATEGORY_V1.PETS, MAIN_CATEGORY_V1.FISHES_AND_AQUARIUM, MAIN_CATEGORY_V1.PET_FOOD_AND_ACCESSORIES,
    MAIN_CATEGORY_V1.DOGS, MAIN_CATEGORY_V1.FASHION, MAIN_CATEGORY_V1.MEN_FASHION, MAIN_CATEGORY_V1.WOMEN_FASHION,
    MAIN_CATEGORY_V1.KIDS_FASHION, MAIN_CATEGORY_V1.COMMERCIAL_VEHICLES_SPARE];

export const FAQS = {
    [MAIN_CATEGORY.BIKES]: {
        LOCATION_L1,
        LOCATION_L3
    },
    [MAIN_CATEGORY.ELECTRONICS]: commonContentType,
    [MAIN_CATEGORY.REAL_ESTATE]: {
        LOCATION_L3
    },
    [MAIN_CATEGORY.JOBS]: {
        ...commonContentType,
        GENERAL
    },
    [MAIN_CATEGORY.MOBILES]: {
        LOCATION_L1,
        LOCATION_L3
    },
    [MAIN_CATEGORY.MOBILE_PHONES]: {
        LOCATION_L3,
        LOCATION_L3_BRAND
    },
    [MAIN_CATEGORY.ACCESSORIES]: {
        LOCATION_L3,
        LOCATION_L3_TYPE: 'location_l3_type'
    },
    [MAIN_CATEGORY.TABLETS]: {
        LOCATION_L3
        // As per this task https://sobek.atlassian.net/browse/OLXIN-1992 we are removing Type filter.
        // LOCATION_L3_TYPE: 'location_l3_type'
    },
    [MAIN_CATEGORY.CARS]: {
        GENERAL,
        WITHOUT_BRAND_WITH_LOCATION: 'without_brand_with_location',
        BRAND_WITHOUT_LOCATION: 'brand_without_location',
        BRAND_WITH_LOCATION: 'brand_with_location'
    },
    [MAIN_CATEGORY.HOUSES_FOR_SALE]: {
        LOCATION_L3
    },
    [MAIN_CATEGORY.HOUSES_FOR_RENT]: {
        LOCATION_L3
    },
    [MAIN_CATEGORY.LANDS_AND_PLOTS]: {
        LOCATION_L3,
        LOCATION_L3_FOR_SALE: 'location_l3_for_sale',
        LOCATION_L3_FOR_RENT: 'location_l3_for_rent'
    },
    [MAIN_CATEGORY.SHOPS_AND_OFFICES_FOR_RENT]: {
        LOCATION_L3,
        LOCATION_L3_FURNISHING
    },
    [MAIN_CATEGORY.SHOPS_AND_OFFICES_FOR_SALE]: {
        LOCATION_L3,
        LOCATION_L3_FURNISHING
    },
    [MAIN_CATEGORY.PG_AND_GUEST_HOUSES]: {
        LOCATION_L3,
        LOCATION_L3_SUBTYPE: 'location_l3_subtype',
        LOCATION_L3_FURNISHING
    },
    [MAIN_CATEGORY.MOTORCYCLES]: {
        LOCATION_L1,
        LOCATION_L3,
        LOCATION_L3_BRAND,
        LOCATION_L3_MODEL: 'location_l3_model'
    },
    [MAIN_CATEGORY.SCOOTERS]: {
        LOCATION_L3,
        LOCATION_L3_BRAND,
        LOCATION_L3_MODEL: 'location_l3_model'
    },
    [MAIN_CATEGORY.BICYCLES]: {
        LOCATION_L3,
        LOCATION_L3_BRAND
    }
};
export const FAQS_V2 = {
    [MAIN_CATEGORY_V1.BIKES]: {
        LOCATION_L1,
        LOCATION_L3
    },
    [MAIN_CATEGORY_V1.ELECTRONICS]: commonContentType,
    [MAIN_CATEGORY_V1.REAL_ESTATE]: {
        LOCATION_L3
    },
    [MAIN_CATEGORY_V1.JOBS]: {
        ...commonContentType,
        GENERAL
    },
    [MAIN_CATEGORY_V1.MOBILES]: {
        LOCATION_L1,
        LOCATION_L3
    },
    [MAIN_CATEGORY_V1.MOBILE_PHONES]: {
        LOCATION_L3,
        LOCATION_L3_BRAND
    },
    [MAIN_CATEGORY_V1.ACCESSORIES]: {
        LOCATION_L3,
        LOCATION_L3_TYPE: 'location_l3_type'
    },
    [MAIN_CATEGORY_V1.TABLETS]: {
        LOCATION_L3
    },
    [MAIN_CATEGORY_V1.CARS]: {
        GENERAL,
        WITHOUT_BRAND_WITH_LOCATION: 'without_brand_with_location',
        BRAND_WITHOUT_LOCATION: 'brand_without_location',
        BRAND_WITH_LOCATION: 'brand_with_location'
    },
    [MAIN_CATEGORY_V1.HOUSES_FOR_SALE]: {
        LOCATION_L3
    },
    [MAIN_CATEGORY_V1.HOUSES_FOR_RENT]: {
        LOCATION_L3
    },
    [MAIN_CATEGORY_V1.LANDS_AND_PLOTS]: {
        LOCATION_L3,
        LOCATION_L3_FOR_SALE: 'location_l3_for_sale',
        LOCATION_L3_FOR_RENT: 'location_l3_for_rent'
    },
    [MAIN_CATEGORY_V1.SHOPS_AND_OFFICES_FOR_RENT]: {
        LOCATION_L3,
        LOCATION_L3_FURNISHING
    },
    [MAIN_CATEGORY_V1.SHOPS_AND_OFFICES_FOR_SALE]: {
        LOCATION_L3,
        LOCATION_L3_FURNISHING
    },
    [MAIN_CATEGORY_V1.PG_AND_GUEST_HOUSES]: {
        LOCATION_L3,
        LOCATION_L3_SUBTYPE: 'location_l3_subtype',
        LOCATION_L3_FURNISHING
    },
    [MAIN_CATEGORY_V1.MOTORCYCLES]: {
        LOCATION_L1,
        LOCATION_L3,
        LOCATION_L3_BRAND,
        LOCATION_L3_MODEL: 'location_l3_model'
    },
    [MAIN_CATEGORY_V1.SCOOTERS]: {
        LOCATION_L3,
        LOCATION_L3_BRAND,
        LOCATION_L3_MODEL: 'location_l3_model'
    },
    [MAIN_CATEGORY_V1.BICYCLES]: {
        LOCATION_L3,
        LOCATION_L3_BRAND
    },
    ...appendLocationToCategory({
        LOCATION_L3: categoriesForL3
    })
};

export const FAQ_TRACK = {
    FAQ_CLICKED: 'faq_clicked',
    FAQ_DISPLAYED: 'faq_displayed',
    LISTING_RESULTS: 'listings_results'
};
