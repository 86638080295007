import React, { memo, useEffect } from 'react';
import { compose } from 'redux';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';

import withRouter from 'HOCs/withRouter';
import withTrack from 'HOCs/withTrack/withTrack';

import { getFaq } from 'Actions/faq';

import { categoriesByFilterSelector } from 'Selectors/categories';
import { getAbundanceFilterInfo, getAppliedFiltersLabels, getParsedAppliedFilters } from 'Selectors/filtersTanak';
import { getPopularLocationsLeftBar } from 'Selectors/popularLocations';

import { getFilteredNestedSelect } from 'Helpers/filtersTanak';
import { FAQ_TRACK } from 'Constants/faq';

import {
    getFaqDynamicValues,
    getMinimumPrice,
    getMultipleFilterSelected,
    getPlotSize,
    getTransformedMakeModels,
    interpolateContent
} from './FAQ.helpers';
import FAQ from './FAQ';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';

const FAQWrapperV2 = ({
    category,
    faqContent,
    getFaqContents,
    parentCategoryId,
    track,
    ...restProps
}) => {
    const isMultipleFilterSelected = getMultipleFilterSelected(restProps?.selectedFilters || []);

    useEffect(() => {
        track(FAQ_TRACK.LISTING_RESULTS, { faq_available: true });
        track(FAQ_TRACK.FAQ_DISPLAYED, { category_id: category?.id });

        if (restProps.selectedLocation && restProps?.locations?.length > 0) {
            const payload = getFaqDynamicValues(category, parentCategoryId, restProps) || {};

            if (payload.type) {
                getFaqContents(payload.type, payload.content);
            }
        }
    }, [restProps.selectedLocation?.id, restProps?.locations?.length]); // eslint-disable-line react-hooks/exhaustive-deps

    const haveSelectedFilters = restProps?.selectedFilters?.length;

    useEffect(() => {
        if (restProps?.selectedFilters?.length > 0) {
            const payload = getFaqDynamicValues(category, parentCategoryId, restProps) || {};

            if (payload.type && !isMultipleFilterSelected) {
                // need to interpolate on FE, instead of BE.
                delete payload?.content?.top_models;
                getFaqContents(payload?.type, payload?.content);
            }
        }
    }, [haveSelectedFilters]); // eslint-disable-line react-hooks/exhaustive-deps

    let title = '';
    let content = [];

    const payload = interpolateContent(faqContent, restProps, () => getFaqDynamicValues(category, parentCategoryId, restProps));

    title = payload.title;
    content = payload.content;

    const isLocationCityOrCountry = ['CITY', 'COUNTRY'].includes(restProps?.selectedLocation?.type);
    const shouldRenderFAQ = isLocationCityOrCountry && !isMultipleFilterSelected && (content?.length > 0);

    if (shouldRenderFAQ) {
        return <FAQ { ...({ content, id: parentCategoryId, title, track }) } />;
    }

    return <></>;
};

FAQWrapperV2.propTypes = {
    category: PropTypes.object,
    categoryFilters: PropTypes.object,
    faqContent: PropTypes.object,
    selectedFilters: PropTypes.array,
    getFaqContents: PropTypes.func.isRequired,
    intl: PropTypes.shape({
        formatMessage: PropTypes.func.isRequired,
        formatNumber: PropTypes.func.isRequired
    }).isRequired,
    location: PropTypes.shape({
        query: PropTypes.object,
        pathname: PropTypes.string
    }),
    parentCategoryId: PropTypes.string,
    selectedLocation: PropTypes.object,
    sessionFeatures: PropTypes.arrayOf(PropTypes.string),
    topModels: PropTypes.array,
    track: PropTypes.func
};

FAQWrapperV2.defaultProps = {
    params: {}
};

export const mapDispatchToProps = (dispatch, ownProps) => {
    const { params: { categoryID }} = ownProps;

    return {
        getFaqContents: (type, content) => dispatch(getFaq(categoryID, type, content))
    };
};

const getParams = props => ({
    categoryId: props.params.categoryID,
    locationId: props.params.geoID,
    search: props.params.text,
    params: props.location.query,
    constructionStatus: props.params.constructionStatus,
    type: props.type || ''
});

export const mapStateToProps = (state, ownProps) => {
    const { config, params } = ownProps;
    const categoryId = params?.categoryID;

    const { categories, faq, locations } = state;

    const category = categories?.elements[categoryId];
    const categoriesInfo = categoriesByFilterSelector(state);
    const parentCategoryId = category?.parent_id === 0 ? category?.id : category?.parent_id;

    const filtersInfoByAttr = getAbundanceFilterInfo(state, categoryId, config);
    const appliedFiltersInfo = getParsedAppliedFilters(state, categoryId);

    const transformedFilterInfo = getFilteredNestedSelect(filtersInfoByAttr?.make || {}, filtersInfoByAttr, '', appliedFiltersInfo);
    const minPrice = getMinimumPrice(state);
    const plotSize = getPlotSize(state);

    return {
        category,
        // get to the number of ads for sub_categories.
        categoryFilters: categoriesInfo.find(cf => cf?.id === parentCategoryId),
        // get faq content from redux state to render on the UI.
        faqContent: faq?.elements[category?.id] || faq?.elements[parentCategoryId] || {},
        locations: getPopularLocationsLeftBar(state, getParams(ownProps)),
        // get the minimum price from the item list.
        minPrice,
        // the parent category id.
        parentCategoryId,
        plotSize,
        // get the list of selected filters.
        selectedFilters: getAppliedFiltersLabels(state, categoryId, Object.keys(appliedFiltersInfo || {}) || []) || [],
        selectedLocation: locations?.selectedLocation,
        topModels: getTransformedMakeModels(transformedFilterInfo?.model || {})
    };
};

export { FAQWrapperV2 };

export default memo(compose(
    withSessionFeature,
    injectIntl,
    withRouter,
    withTrack,
    connect(mapStateToProps, mapDispatchToProps)
)(FAQWrapperV2));
