import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { Link } from 'panamera-react-ui';
import { FormattedMessage as Translation, injectIntl } from 'react-intl';

import { locations } from './constants';
import css from './InlineWidget.APP_TARGET.scss';
import { buildURL } from 'Helpers/url';
import withTrack from 'HOCs/withTrack/withTrack';
import { INLINE_WIDGET_TRACK, LISTINGS_RESULTS } from 'Constants/tracking';

export const InlineWidget = React.memo(({ category, location, trackSelectFrom, track }) => {
    const nearByLocations = locations[location?.id] || [];

    function handleTrack(event) {
        const link = event.target.closest('[href]');
        const locationId = link.getAttribute('data-aut-id').split(' ')[1];

        if (locationId) {
            track?.(INLINE_WIDGET_TRACK.SELECT, { choosen_option: location?.id });
            track?.(LISTINGS_RESULTS, { select_from: trackSelectFrom ? `${trackSelectFrom},inline` : 'inline' });
        }
    }

    useEffect(() => {
        if (nearByLocations?.length > 0) {
            track?.(INLINE_WIDGET_TRACK.SHOWN);
        }
    }, [nearByLocations.length, track]);

    return nearByLocations?.length > 0 ? (
        <div className={ css.inline_widget_container } data-aut-id="inline-widget">
            <h2 className={ css.inline_widget_heading }>
                <Translation id="nearby_locations" />
            </h2>

            <div className={ css.inline_widget_locations } onClick={ handleTrack }>
                { nearByLocations.map(location =>
                    (<Link
                        className={ css.inline_widget_location_chips }
                        data-aut-id={ `inline-widget-location ${location?.id}` }
                        key={ location?.id }
                        title={ `Used Cars in ${location.name}` }
                        to={ buildURL({ category, location }) }
                    >
                        { location.name }
                    </Link>)
                ) }
            </div>
        </div>
    ) : null;
});

InlineWidget.propTypes = {
    category: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
    trackSelectFrom: PropTypes.string,
    track: PropTypes.func.isRequired
};

InlineWidget.displayName = 'InlineWidget';

export const mapStateToProps = (state, ownProps) => ({
    category: state.categories.elements[ownProps?.categoryID] || {},
    location: state.locations.selectedLocation || {},
    trackSelectFrom: state.track.selectFrom
});

export default compose(injectIntl, withTrack, connect(mapStateToProps))(InlineWidget);
