import React from 'react';
import PropTypes from 'prop-types';
import { injectIntl } from 'react-intl';
import classnames from 'classnames';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { MAX_ITEMS_SHOW, PREFIX_AUT_ID } from './constants';
import ItemLocation from './ItemLocation';
import { selectedLocationSelector } from 'Selectors/location';
import cssLocationGroup from './LocationGroup.scss';
import css from './LastItemGroup.scss';

export class LastItemGroup extends React.Component {
    static propTypes = {
        items: PropTypes.array,
        onSelect: PropTypes.func.isRequired,
        level: PropTypes.number.isRequired,
        onViewAll: PropTypes.func.isRequired,
        intl: PropTypes.shape({
            formatMessage: PropTypes.func.isRequired
        }).isRequired,
        selectedLocation: PropTypes.shape({
            id: PropTypes.number.isRequired,
            name: PropTypes.string.isRequired
        }),
        isFiltersV2: PropTypes.bool,
        category: PropTypes.string
    }

    static defaultProps = {
        items: []
    }

    constructor(props) {
        super(props);
        this.state = {
            collapsed: !props.isFiltersV2
        };
    }

    translations = {
        viewAll: { id: 'viewAll' },
        viewMore: { id: 'viewMore' },
        viewLess: { id: 'viewLess' }
    }

    itemToItemLocation = item => (
        <li key={ item.id }>
            <ItemLocation item={ item } onClick={ this.props.onSelect } isFiltersV2={ this.props.isFiltersV2 } category={ this.props.category } />
        </li>
    );

    toogleCollapsed = () => this.setState({ collapsed: !this.state.collapsed });

    render() {
        const { collapsed } = this.state;
        const { level, items, intl, selectedLocation, isFiltersV2 } = this.props;
        const itemsShow = items.slice(0, MAX_ITEMS_SHOW).map(this.itemToItemLocation);
        const itemsHide = items.slice(MAX_ITEMS_SHOW, items.length).map(this.itemToItemLocation);
        const textButton = collapsed ? this.translations.viewMore : this.translations.viewLess;

        return (
            <ul className={ cssLocationGroup.locationGroup } data-aut-id={ `${PREFIX_AUT_ID}${level}` }>
                { itemsShow }
                <div className={ classnames(css.itemsHide, { [css.collapsed]: collapsed }) } >
                    { itemsHide }
                </div>
                { items.length > MAX_ITEMS_SHOW && !isFiltersV2 && (
                    <div className={ css.button } onClick={ this.toogleCollapsed }>
                        { intl.formatMessage(textButton) }
                    </div>
                )}
                { !collapsed && !isFiltersV2 && (
                    <div className={ css.button } onClick={ this.props.onViewAll }>
                        { `${intl.formatMessage(this.translations.viewAll)} ${selectedLocation.name}` }
                    </div>
                )}
            </ul>
        );
    }
}

export const mapStateToProps = state => ({
    selectedLocation: selectedLocationSelector(state)
});

export default compose(
    connect(mapStateToProps),
    injectIntl
)(LastItemGroup);
