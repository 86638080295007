import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import classNames from 'classnames';
import { FormattedMessage as Translation } from 'react-intl';
import css from './ActionButtons.scss';
import { Link } from 'panamera-react-ui';
import { withFeatureFlag as withLQFeatureFlag } from 'LaquesisEOL/withFeatureFlag';
import NewIconButton from 'Components/ThematicNewIconButton/ThematicNewIconButton';

import Button from 'Components/Button/Button';
import withRouter from 'HOCs/withRouter';
import { injectIntl } from 'react-intl';
import withConfig from 'HOCs/withConfig/withConfig';
import withTrack from 'HOCs/withTrack/withTrack';
import { getUserImage } from 'Helpers/images';
import NotificationHub from 'Components/NotificationHub/NotificationHub';
import AvatarDropdown from 'Components/AvatarDropdown/AvatarDropdown';
import setLoginModalState from 'Actions/setLoginModalState';
import {
    getLoggedUser,
    userIsLogged
} from 'Selectors/user';
import withDirection from 'HOCs/withDirection/withDirection';
import SellButton from 'Components/SellButton/SellButton';
import { getUserDataSelector } from 'Selectors/user';
import { THEMES } from 'Constants/bundles';
import { OLXAUTOS } from 'Constants/tracking';
import { LQ_SHORTLIST_FEATURE } from 'Constants/items';

export class ActionButtons extends React.PureComponent {
  static propTypes = {
      showUnreadChat: PropTypes.bool,
      setLoginModalState: PropTypes.func.isRequired,
      config: PropTypes.shape({
          get: PropTypes.func.isRequired
      }).isRequired,
      type: PropTypes.oneOf(['dark', 'light']),
      loggedUser: PropTypes.object.isRequired,
      _userIsLogged: PropTypes.bool.isRequired,
      className: PropTypes.string,
      router: PropTypes.object.isRequired,
      loginCallback: PropTypes.func,
      direction: PropTypes.oneOf(['ltr', 'rtl']).isRequired,
      onClickTrack: PropTypes.func,
      user: PropTypes.object,
      track: PropTypes.func.isRequired,
      intl: PropTypes.shape({
          formatMessage: PropTypes.func.isRequired
      }).isRequired
  };

  static defaultProps = {
      showUnreadChat: false,
      type: 'dark',
      className: '',
      loginCallback: () => { },
      onClickTrack: () => { }
  };

  constructor(props) {
      super(props);
      this.translations = {
          postBtnText: <Translation id="sell" />
      };
      const olxAutos = props.config.get('olxAutos');

      this.isMyZoneFinanceEnabled = olxAutos && olxAutos.financeMyZoneEnabled;
      this.theme = props.config.get('theme', 'id');
      this.isOtoplus = this.theme === THEMES.OTOPLUS;
  }

  toggleLoginModal = () => {
      this.props.setLoginModalState({ state: true, redirectTo: '', trackProps: {}, callback: this.props.loginCallback });
  };

  onClickPost = e => {
      e.preventDefault();

      this.props.onClickTrack('sell');

      if (this.props._userIsLogged) {
          this.props.router.push({
              pathname: '/post'
          });
      }
      else {
          this.props.setLoginModalState({ state: true, redirectTo: '/post', trackProps: { origin: 'posting' }});
      }
  };

  onClickMyFavorites = e => {
      e.preventDefault();

      this.props.onClickTrack('favorites');

      if (this.props._userIsLogged) {
          this.props.router.push({
              pathname: '/wishlist'
          });
      }
      else {
          this.props.setLoginModalState({ state: true, redirectTo: '/wishlist', trackProps: { origin: 'wishlist' }});
      }
  };

  onClickChat = () => {
      this.props.onClickTrack('chat');
  }

  handleContactButtonClick = () => {
      this.props.track(OLXAUTOS.OLXAUTOS_LINKS_TAPPED, { select_from: 'header', chosen_option: this.props.intl.formatMessage({ id: 'contactUs' }) });
  }

  renderContactUsButton = () => {
      const olxAutos = this.props.config.get('olxAutos') || {};
      const contactUsUrl = olxAutos.contactUsPath;

      return (
          <Link
              key="contactUs"
              to={ `/${contactUsUrl}` }
              data-aut-id="btnContactUs"
              onClick={ this.handleContactButtonClick }
          >
              <Translation id="contactUs" />
          </Link>
      );
  }

  render() {
      const { loggedUser, _userIsLogged, showUnreadChat, config, direction, user } = this.props;
      const actionButtons = [];
      const staticAssetUrl = config.get('staticAssets');
      const disableUserLogin = config.get('disableUserLogin') || false;
      const logUser = user || loggedUser;

      actionButtons.push(
          <Link
              key="wishlist"
              to="/wishlist"
              className={ css.icons }
              onClick={ this.onClickMyFavorites }
              rel="nofollow"
              data-aut-id="btnWishlist"
          >
              <NewIconButton
                  name="favorite"
                  icon="favoriteOff"
                  direction={ direction }
              />
          </Link>
      );

      if (_userIsLogged) {
          actionButtons.push(
              <Link
                  key="chat"
                  to="/nf/chat"
                  className={ classNames(css.icons, { [css.notification]: showUnreadChat }, css.classifiedNotification) }
                  rel="nofollow"
                  data-aut-id="btnChat"
                  onClick={ this.onClickChat }
              >
                  <NewIconButton
                      name="chat"
                      icon="chatNavigation"
                      direction={ direction }
                  />
              </Link>
          );
          if (config.get('notificationHub').enabled) {
              actionButtons.push(
                  <div
                      key="notificationHub"
                      className={ css.icons }
                      data-aut-id="notificationHub"
                  >
                      <NotificationHub />
                  </div>
              );
          }
          actionButtons.push(

              <div
                  className={ css.avatarContainer }
                  key="avatarDropdown"
              >
                  <AvatarDropdown
                      imageURl={ getUserImage(staticAssetUrl, logUser) }
                      userName={ loggedUser.name }
                  />
              </div>
          );
      }
      else if (!disableUserLogin) {
          actionButtons.push(
              <Button
                  key="login"
                  type="tertiaryBtn"
                  data-aut-id="btnLogin"
                  onClick={ this.toggleLoginModal }
                  className={ classNames(css.buttons, css.loginBtn) }
              >
                  <Translation id="logIn" />
              </Button>
          );
      }

      actionButtons.push(
          <SellButton
              key="postBtn"
              href="/post"
              data-aut-id="btnSell"
              onClick={ this.onClickPost }
              label={ this.translations.postBtnText }
              theme={ this.theme }
          />
      );

      return (
          <div
              className={ classNames(css.actionButtons,
                  this.props.className,
                  css[this.props.type]
              ) }
              data-aut-id="actionButtons"
          >
              {actionButtons}
          </div>
      );
  }
}

export const mapStateToProps = state => {
    return {
        user: getUserDataSelector(state),
        showUnreadChat: state.toggleUnreadChats,
        loggedUser: getLoggedUser(state),
        _userIsLogged: !!userIsLogged(state)
    };
};

export const mapDispatchToProps = dispatch => {
    return {
        setLoginModalState: state => dispatch(setLoginModalState(state))
    };
};

export default compose(
    withRouter,
    withConfig,
    withTrack,
    withDirection,
    withLQFeatureFlag(LQ_SHORTLIST_FEATURE),
    connect(mapStateToProps, mapDispatchToProps),
    injectIntl
)(ActionButtons);
