/* eslint-disable no-unused-vars */
/* eslint-disable max-lines */
/* global window */
/* eslint-disable no-magic-numbers */
import React, { useEffect, useState } from 'react';
import css from './FooterInterlinks.scss';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { withConfig } from 'HOCs/withConfig/withConfig';
import { buildURL } from 'Helpers/url';
import { getPopularLocationsByLimit } from 'Actions/locations';
import { categoryIdSelector } from 'Selectors/categories';
import { getCategoryTree } from 'Reducers/componentHelpers';
import { itemsMetadataSelector } from 'Selectors/items';
import { selectedLocationSelector } from 'Selectors/location';
import { getTags } from 'Selectors/filtersTanak';
import { categoryWiseFooterLinkText, MOTORCYCLES, BIKES, VEHICLES_CATEGORY_ID, ONE_CRORE, ONE_LAKH, TWENTY_ITEMS, LOCATION_TYPE, LOCATION_LIMIT, categoryWiseFooterLinkSeoText } from '../../constants/footerInterLinkData';
import { isAtCityLevelAndCategoryTreeSelected } from 'Helpers/seoFooter';
import { Link } from 'panamera-react-ui';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';
import { MAIN_CATEGORY } from 'Constants/categories';
import { getLocale } from 'Selectors/config';

const FooterInterlinks = ({
    itemsMetadata,
    categoryTreeSelected,
    category,
    selectedLocation,
    appliedFilters,
    getPopularLocationsData,
    topPopularCityData,
    marketConfig,
    locale
}) => {
    const [urlData, setUrlData] = useState([]);
    const [linksInitialized, setLinksInitialized] = useState(false);

    const isAtCityLevelAndCategoryTreeSelectedFlag = isAtCityLevelAndCategoryTreeSelected(categoryTreeSelected, selectedLocation);

    const getCategoryWiseFooterLinkText = (categoryKey, filterType, payload) => {
        const categoryFooterText = payload[categoryKey];

        if (!categoryFooterText) {
            return payload.defaultText;
        }

        if (categoryFooterText[filterType]) {
            return categoryFooterText[filterType];
        }
        return categoryFooterText.defaultText;
    };

    const modifyFooterText = ({ categoryName, filterType, id }) => {
        const text = getCategoryWiseFooterLinkText(id, filterType, categoryWiseFooterLinkSeoText);
        const content = categoryWiseFooterLinkSeoText[id];

        categoryName = content?.categoryName || categoryName;

        return { categoryName, text };
    };

    const generateAllFilterLinkText = (cityName, filterType, filterData) => {
        const { L1, L2 } = categoryTreeSelected;
        const categoryId = L1.id === VEHICLES_CATEGORY_ID && L2 ? L2.id : L1.id;
        let categoryName = L2 ? L2.name : L1.name;
        const text = getCategoryWiseFooterLinkText(categoryId, filterType, categoryWiseFooterLinkText);

        let filterItemValue;

        switch (filterType) {
            case 'year':
                filterItemValue = filterData.min;
                break;
            case 'model': {
                filterItemValue = filterData.includes('-')
                    ? filterData.split('-').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')
                    : filterData;
                const regex = new RegExp(`\\b${ categoryName.slice(0, -1) }s?\\b`, 'gi');

                filterItemValue = filterItemValue.replace(regex, '').trim();
                break;
            }
            case 'mileage':
                filterItemValue = Math.ceil(filterData.max / 1000) * 1000;
                break;
            case 'price': {
                const maxValue = Math.ceil(filterData.max / 1000) * 1000;
                let lakhValue = (`${(maxValue / ONE_LAKH).toFixed(1) } Lakh`).replace('.0', '');
                let croreValue = (`${(maxValue / ONE_CRORE).toFixed(1) } Crore`).replace('.0', '');

                if ((maxValue / ONE_CRORE).toFixed(1) > 1) {
                    croreValue = (`${(maxValue / ONE_CRORE).toFixed(1) } Crores`).replace('.0', '');
                }
                else if ((maxValue / ONE_LAKH).toFixed(1) > 1) {
                    lakhValue = (`${(maxValue / ONE_LAKH).toFixed(1) } Lakhs`).replace('.0', '');
                }

                const formatNumber = num => (num / 100000 >= 100 ? croreValue : lakhValue).replace('.0', '');
                const thousandValue = `₹ ${locale ? maxValue.toLocaleString(locale) : maxValue}`;

                filterItemValue = maxValue >= 100000 ? formatNumber(maxValue) : thousandValue;
                break;
            }
            default:
                filterItemValue = filterData.max || filterData;
                break;
        }

        categoryName = categoryName === MOTORCYCLES ? BIKES : categoryName;
        if (categoryName === 'Other Jobs') {
            categoryName = 'Other';
        }
        if (categoryName === 'Bicycles') {
            categoryName = 'Cycles';
        }

        let modifiedPayload = {
            categoryName: null,
            filterItemValue: null,
            text: null
        };

        modifiedPayload = modifyFooterText({ categoryName, filterType, id: category?.id });

        if (category?.id === MAIN_CATEGORY.HOUSES_FOR_RENT) {
            if (filterType === 'bachelors' && /not\s+allowed/g.test(filterItemValue)) {
                modifiedPayload.filterItemValue = 'Couples & Family';
            }
            else if (filterType === 'bachelors' && /allowed/g.test(filterItemValue)) {
                modifiedPayload.filterItemValue = 'Bachelors';
            }
            else {
                modifiedPayload.filterItemValue = filterItemValue;
            }
        }

        return (modifiedPayload?.text || text)
            .replace(/{category}/g, modifiedPayload?.categoryName || categoryName)
            .replace(/{value}/g, modifiedPayload?.filterItemValue || filterItemValue)
            .replace(/{city}/g, cityName)
            .replace(/Jobs\s+Jobs/g, 'Jobs');
    };

    const makeLocationUrls = () => {
        return topPopularCityData.map((item, index) => {
            const locationUrl = buildURL({
                location: item,
                category,
                params: {
                    filter: {}
                }
            });

            const linkText = generateAllFilterLinkText(item.name, 'location', '');

            return (
                <React.Fragment key={ index }>
                    <Link
                        className={ css.footerInterLink }
                        to={ locationUrl }
                    >
                        {linkText}
                    </Link>
                    {index !== topPopularCityData.length - 1 && <span> | </span>}
                </React.Fragment>
            );
        });
    };

    const reorderCategories = (filters, order) => {
        const orderSet = new Set(order);
        const reorderedFilters = [];
        const remainingFilters = [];

        for (const filter of filters) {
            if (orderSet.has(filter.id)) {
                reorderedFilters.push(filter);
            }
            else {
                remainingFilters.push(filter);
            }
        }
        reorderedFilters.sort((a, b) => order.indexOf(a.id) - order.indexOf(b.id));

        return [...reorderedFilters, ...remainingFilters];
    };

    const filterAllModelsForMake = (modelData, make) => {
        if (!modelData || !modelData.values || !Array.isArray(modelData.values)) {
            return [];
        }
        return modelData.values.filter(item => item.id.startsWith(make));
    };

    const generateMissingYearRanges = yearData => {
        const newArray = [];
        let currentId = 1;

        for (const obj of yearData) {
            const minYear = obj.range.min;
            const maxYear = obj.range.max;
            const yearCount = obj.count;

            // Add the original object with id
            newArray.push({
                id: currentId++,
                name: `[${minYear} - ${maxYear}]`,
                range: { min: minYear, max: maxYear },
                count: yearCount
            });

            // Check if we need to add the overlapping range
            if (maxYear < yearData[yearData.length - 1].range.max) {
                newArray.push({
                    id: currentId++,
                    name: `[${maxYear} - ${maxYear + 1}]`,
                    range: { min: maxYear, max: maxYear + 1 },
                    count: 0
                });
            }
        }
        const lastTwentyObjects = newArray.slice(-TWENTY_ITEMS);

        return lastTwentyObjects;
    };

    const updateObjectInArray = (oldFilterData, objectId, key, newFilterData) => {
        return oldFilterData.map(obj => {
            if (obj.id === objectId) {
                return { ...obj, [key]: newFilterData };
            }
            return obj;
        });
    };

    const getTopCountItems = categoryFilterData => {
        return categoryFilterData.values
            .filter(v => v.count > 0)
            .sort((a, b) => b.count - a.count)
            .slice(0, TWENTY_ITEMS)
            .sort((a, b) => a.id - b.id);
    };

    const removeOtherData = itemData => {
        return itemData.filter(item =>
            !/other/i.test(item.name)
        );
    };

    const makeDynamicLinks = categories => {
        const finalData = [];
        const excludedCategories = ['category', 'location', 'inspected', 'price_per_ft', 'price_per_yd'];
        let filterCategory = reorderCategories(categories.filters, ['make', 'model']);
        const yearData = categories.filters.filter(item => item.id === 'year');

        if ('L1' in categoryTreeSelected && !('L2' in categoryTreeSelected)) {
            excludedCategories.push('price');
        }

        if (yearData.length !== 0) {
            const yearRangeData = generateMissingYearRanges(yearData[0].values);

            filterCategory = updateObjectInArray(filterCategory, 'year', 'values', yearRangeData);
        }

        for (let i = 0; i < filterCategory.length; i++) {
            const categoryItem = filterCategory[i];

            if (categoryItem.id === 'make' || categoryItem.id === 'model') {
                categoryItem.values = removeOtherData(categoryItem.values);
            }

            if (appliedFilters && appliedFilters.length === 1 && appliedFilters[0].filterId === 'make' && categoryItem.id === 'model') {
                const makeId = appliedFilters[0].props.id;

                categoryItem.values = filterAllModelsForMake(categoryItem, makeId);
            }

            if (!excludedCategories.includes(categoryItem.id)) {
                const topCountItems = getTopCountItems(categoryItem);

                topCountItems.forEach(item => {
                    const value = (categoryItem.type === 'range-slider' || categoryItem.type === 'range-input') ? item.range : item.id;
                    const params = { filter: { [categoryItem.id]: value }};
                    const links = buildURL({
                        location: selectedLocation,
                        category,
                        params
                    });

                    let linkText;

                    if (categoryItem.id === 'model') {
                        linkText = generateAllFilterLinkText(selectedLocation.name, categoryItem.id, item.id);
                    }
                    else {
                        linkText = item.range ? generateAllFilterLinkText(selectedLocation.name, categoryItem.id, item.range) : generateAllFilterLinkText(selectedLocation.name, categoryItem.id, item.name);
                    }

                    const obj = {
                        parentID: categoryItem.id,
                        parentName: categoryItem.id === 'make' ? 'Make' : categoryItem.description,
                        parentType: categoryItem.type,
                        itemId: item.id,
                        itemName: item.name,
                        itemRange: item.range ? item.range : '',
                        range: !!item.range,
                        to: links,
                        fullTextToShow: linkText || 'Links'
                    };

                    finalData.push(obj);
                });
            }
        }
        setUrlData(finalData);
    };

    const shouldHideFilter = filter => {
        const { hideFiltersForFooterInterlinks } = marketConfig.get('SEO') || {};

        return filter && ((hideFiltersForFooterInterlinks[category?.id] || []).indexOf(filter) > -1);
    };

    if (!linksInitialized && urlData.length < 1 && isAtCityLevelAndCategoryTreeSelectedFlag && itemsMetadata && itemsMetadata.filters) {
        makeDynamicLinks(itemsMetadata);
        setLinksInitialized(true);
    }

    useEffect(() => {
        if (isAtCityLevelAndCategoryTreeSelectedFlag && itemsMetadata && itemsMetadata.filters) {
            makeDynamicLinks(itemsMetadata);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsMetadata]);

    useEffect(() => {
        getPopularLocationsData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const groupedItems = urlData.reduce((acc, item) => {
        if (!acc[item.parentName]) {
            acc[item.parentName] = [];
        }
        acc[item.parentName].push(item);
        return acc;
    }, {});

    return (
        <div>
            {isAtCityLevelAndCategoryTreeSelectedFlag && appliedFilters.length <= 1 && topPopularCityData && (
                <div className={ css.locationLinkWrapper }>
                    <span data-aut-id="locationHead" className={ css.footerInterLinkHeaders }>Location : </span>
                    {makeLocationUrls()}
                </div>
            )}
            <div>
                {isAtCityLevelAndCategoryTreeSelectedFlag && appliedFilters.length <= 1 && Object.keys(groupedItems).map(parentName => {
                    const filter = groupedItems[parentName]?.[0]?.parentID || undefined;

                    return shouldHideFilter(filter) ? undefined : (
                        <div key={ parentName } className={ css.footerInterLinkWrapper }>
                            <span className={ css.footerInterLinkHeaders }>{parentName} : </span>
                            <span>
                                {groupedItems[parentName].map(item => (
                                    <Link
                                        key={ item.itemName }
                                        className={ css.footerInterLink }
                                        to={ item.to }
                                    >
                                        {item.fullTextToShow}

                                    </Link>
                                )).reduce((prev, curr) => [prev, ' | ', curr])}
                            </span>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

FooterInterlinks.propTypes = {
    itemsMetadata: PropTypes.shape({
        filters: PropTypes.array
    }),
    categoryTreeSelected: PropTypes.shape({
        L1: PropTypes.object,
        L2: PropTypes.object
    }),
    category: PropTypes.object,
    selectedLocation: PropTypes.object,
    appliedFilters: PropTypes.array,
    getPopularLocationsData: PropTypes.func,
    topPopularCityData: PropTypes.array,
    marketConfig: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    locale: PropTypes.string
};

export const mapStateToProps = (state, ownProps) => {
    const categoryID = categoryIdSelector(state, ownProps.params);
    let itemsMetadata = itemsMetadataSelector(state);

    itemsMetadata = {
        ...itemsMetadata,
        filters: itemsMetadata?.filters.map(filter => {
            if (filter?.id === 'first_owner') {
                filter.values = filter?.values.slice(0, 2) || [];
                return filter;
            }
            return filter;
        })
    };

    return {
        categoryTreeSelected: getCategoryTree(state.categories.elements, categoryID),
        itemsMetadata,
        category: state.categories.elements[categoryID],
        selectedLocation: selectedLocationSelector(state),
        appliedFilters: getTags(state, categoryID),
        topPopularCityData: state.locations.topPopularLocations.data,
        locale: getLocale(state)
    };
};

export const mapDispatchToProps = dispatch => ({
    getPopularLocationsData: () => dispatch(getPopularLocationsByLimit(LOCATION_TYPE, LOCATION_LIMIT))
});

export default compose(
    withConfig('marketConfig'),
    withSessionFeature,
    connect(mapStateToProps, mapDispatchToProps)
)(FooterInterlinks);
