import React, { useState, useEffect, useRef } from 'react';
import { FormattedMessage as Translation } from 'react-intl';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import css from './SearchBarAnimation.scss';
import { getSearchBarAnimationTags } from 'Selectors/config';
import { SEARCH_ANIMATION_FALLBACK_TAGS, SEARCH_ANIMATION_TIME, RESET_ANIMATION_TIME } from 'Constants/config';

export const SearchBarAnimation = ({ tags = SEARCH_ANIMATION_FALLBACK_TAGS }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    const wrapperRef = useRef(null);
    const isAnimatingRef = useRef(true);

    const extendedTags = [...tags, tags[0]];

    useEffect(() => {
        const interval = setInterval(() => {
            if (currentIndex === tags.length) {
                // When reaching the duplicate, reset to the first word
                isAnimatingRef.current = false;
                setCurrentIndex(0);

                setTimeout(() => {
                    isAnimatingRef.current = true;
                    setCurrentIndex(1);
                }, RESET_ANIMATION_TIME);
            }
            else {
                setCurrentIndex(prevIndex => (prevIndex + 1) % extendedTags.length);
            }
        }, SEARCH_ANIMATION_TIME);

        return () => {
            clearInterval(interval);
        };
    }, [currentIndex, tags.length, extendedTags.length]);

    const wrapperStyle = {
        // eslint-disable-next-line no-magic-numbers
        top: `-${currentIndex * 100}%`,
        transition: isAnimatingRef.current ? 'top 0.5s ease-in-out' : 'none'
    };

    return (
        <div className={ css.searchBar }>
            <span className={ css.fixedText }><Translation id="search" /></span>
            <div className={ css.animatedTextContainer }>
                <div
                    ref={ wrapperRef }
                    className={ css.animatedTextWrapper }
                    style={ wrapperStyle }
                >
                    {extendedTags.map((word, index) => (
                        <div key={ index } className={ css.animatedWord }>
                            &quot;{word}&quot;
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

SearchBarAnimation.propTypes = {
    tags: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    tags: getSearchBarAnimationTags(state)
});

export default compose(connect(mapStateToProps, null))(SearchBarAnimation);
