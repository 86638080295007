/* eslint-disable no-magic-numbers */
import React from 'react';
import PropTypes from 'prop-types';
import ItemCardCarInd from 'Components/Listing/listingMXComponents/ItemCardCarInd/ItemCardCarInd';
import SectionTitle from 'Components/Listing/components/Sections/SectionTitle';
import { getIsFeaturedAd, isCocoFofoUser, getVasStripVisibilityForItemRow, getMinPPInd, getMaxPPInd, getIndModFromSection } from 'Helpers/item';
import BrandPromise from 'Components/BrandPromise/BrandPromise';
import { USER_TYPE } from 'Constants/users';
import ItemPostPlaceholder from 'Components/Listing/components/ItemPostPlaceholder';
import css from '../ItemViewListing/ItemView.APP_TARGET.scss';
import { CONFIG as listConfig } from 'Constants/config';
import CarsPlaceholder from 'Components/Listing/components/CarsPlaceholder';
import { isAutosAvailable } from 'Components/Listing/components/helpers';
import { CARS_CATEGORY_PAGE_SIZE } from 'Constants/items';
import InlineWidget from 'Components/InlineWidget/InlineWidget';
import { MAIN_CATEGORY } from 'Constants/categories';
import { InlineWidgetDesktopOffset, MinimumItemToShowInlineWidgetDesktop } from 'Components/InlineWidget/constants';

const ItemsList = (
    {
        items, isFetching, showStatistics,
        similarAdsCount, similarAdsData, type, enabledRealImpressions, onViewItem, isAdSuggested, isShowVideoTag,
        visualizationType, itemPostPlaceholderOrigin, itemPostPlaceholderPosition, appliedFiltersLength,
        sectionsItems, listingType, getListingDescription, showInspectionTag, isNewUspVasDesign, shouldRenderZRPWidgets,
        filters, onItemClick, source, sectionsOffset, marketConfig, loadingItems, isListingPage, onVasTagTap, openComparePopup, showPlaceHolder, listingBodyType, locationId, showSponseredTag, showDealerTag, fromPage, isDealerProfile, isDealerPreview,
        location, categoryID
    }
) => {
    const placeholderPosition = itemPostPlaceholderPosition(
        marketConfig,
        itemPostPlaceholderOrigin
    );
    const hideVasTagListing = marketConfig.get('hideVasTagListing');
    const isTradeInFFEnabled = marketConfig.get('enableTradeIn');
    const showExchangeCard = marketConfig.get('olxAutos', 'showExchangeCard') || isTradeInFFEnabled;
    const showModernDesign = marketConfig.get(listConfig.SHOWMODERDESIGN);

    let firstCocoFofoAdEncountered = false;
    let isBrandPromisePlaced = false;

    let showVasStrip = true;
    let showVasStripForPP = true;
    let indDisp = 0; // index displacement for sections
    let nextSec; // variable to store next section offset
    let modPPInd = placeholderPosition;
    let minPPInd = getMinPPInd(modPPInd);
    let maxPPInd = getMaxPPInd(minPPInd);

    const itemPlaceholderClassified = () => {
        if (isAutosAvailable(marketConfig, items, locationId.toString()) && itemPostPlaceholderOrigin !== listConfig.HOME) {
            return (<CarsPlaceholder
                key={ listConfig.CARS_PLACEHOLDER }
                className={ showModernDesign ? css.postCard : '' }
                placeholderPosition={ placeholderPosition }
                items={ items }
                /* TO BE REMOVED */
                shouldRun={ false }
            />);
        }
        return (<ItemPostPlaceholder
            key="postPlaceholder"
            itemPostPlaceholderOrigin={ itemPostPlaceholderOrigin }
            visualizationType={ visualizationType }
            className={ css.postCard }
            hideVasStripForPP={ hideVasTagListing || !showVasStripForPP }
        />);
    };

    let brandPromisePosition = null;
    const getItemList = data => data.map((item, itemIndex) => {
        let newItemIndex = itemIndex;

        const currentPage = location?.query?.page > 0 ? location.query.page - 1 : location.query.page || 0;

        newItemIndex = itemIndex + currentPage * CARS_CATEGORY_PAGE_SIZE;
        const hasSection = sectionsOffset && sectionsOffset.includes(newItemIndex);

        // logic for placing brand promise between listing items
        const isFeaturedAd = getIsFeaturedAd(item);

        if (isNewUspVasDesign && (shouldRenderZRPWidgets || appliedFiltersLength > 3)) {
            isBrandPromisePlaced = true;

            brandPromisePosition = null;
        }

        if (!isNewUspVasDesign && !isFeaturedAd && firstCocoFofoAdEncountered && !isBrandPromisePlaced && isListingPage && !similarAdsCount) {
            isBrandPromisePlaced = true;

            brandPromisePosition = itemIndex;

            if (brandPromisePosition % 3) {
                brandPromisePosition = brandPromisePosition + 3 - brandPromisePosition % 3;
            }
        }

        if (!firstCocoFofoAdEncountered) {
            firstCocoFofoAdEncountered = !!isCocoFofoUser(item.user_type, item.category_id);
        }

        if (isNewUspVasDesign && !isBrandPromisePlaced && !similarAdsCount && !similarAdsData?.length) {
            isBrandPromisePlaced = true;

            brandPromisePosition = 12;

            if (brandPromisePosition > items.length) {
                brandPromisePosition = items.length + 3 - items.length % 3;
            }
        }

        if (isNewUspVasDesign && !isBrandPromisePlaced && similarAdsCount && similarAdsData?.length) {
            isBrandPromisePlaced = true;

            brandPromisePosition = 6;
        }

        if (!isBrandPromisePlaced && ((data.length === 1 && firstCocoFofoAdEncountered && !similarAdsData?.length))) {
            isBrandPromisePlaced = true;
            brandPromisePosition = 3;
        }

        if (!hideVasTagListing) {
            const opArr = getIndModFromSection(hasSection, sectionsOffset, itemIndex, indDisp, modPPInd, placeholderPosition, minPPInd, maxPPInd);

            [nextSec, modPPInd, minPPInd, maxPPInd, indDisp] = opArr;
            showVasStrip = getVasStripVisibilityForItemRow(items, itemIndex, modPPInd, maxPPInd, minPPInd, showVasStrip, nextSec, indDisp);
            // if ppInd set flag for pp vas strip as well
            if (placeholderPosition === itemIndex) {
                showVasStripForPP = showVasStrip;
            }
        }

        const ItemCard = ItemCardCarInd;
        const videoTag = (isShowVideoTag && item?.video_status);

        return (
            <React.Fragment key={ `${item.id}_${itemIndex}` }>
                { hasSection
                    && <SectionTitle
                        section={ sectionsItems && sectionsItems.find(section => section.offset === newItemIndex) }
                    />}
                {
                    categoryID === MAIN_CATEGORY.CARS && itemIndex === InlineWidgetDesktopOffset && data.length >= MinimumItemToShowInlineWidgetDesktop && similarAdsData.length === 0
                        ? <InlineWidget categoryID={ categoryID } />
                        : undefined
                }
                <ItemCard
                    key={ `${item.id}_${itemIndex}` }
                    item={ item }
                    friendsInCommon={ false }
                    type={ type }
                    enabledRealImpressions={ enabledRealImpressions }
                    onView={ onViewItem }
                    visualizationType={ visualizationType }
                    showStatistics={ showStatistics }
                    listingType={ listingType }
                    getListingDescription={ getListingDescription }
                    filters={ filters }
                    onItemClick={ onItemClick }
                    source={ source }
                    isAdSuggested={ isAdSuggested }
                    chosenOption={ itemIndex }
                    showInspectionTag={ showInspectionTag }
                    onVasTagTap={ onVasTagTap }
                    openComparePopup={ openComparePopup }
                    hideVasStrip={ hideVasTagListing || !showVasStrip }
                    isListingPage={ isListingPage }
                    listingBodyType={ listingBodyType }
                    isNewUspVasDesign={ isNewUspVasDesign }
                    isShowVideoTag={ videoTag }
                    showSponseredTag={ showSponseredTag }
                    showDealerTag={ showDealerTag }
                    fromPage={ fromPage }
                    isDealerPreview={ isDealerPreview }
                    isDealerProfile={ isDealerProfile }
                    categoryId={ item.category_id }
                />
            </React.Fragment>
        );
    });

    const itemsList = getItemList(items);

    if (placeholderPosition >= items.length) {
        showVasStripForPP = showVasStrip;
    }
    if (showPlaceHolder && placeholderPosition && items.length) {
        const ItemPlaceholder = (!similarAdsCount && items?.length >= 6 && itemPlaceholderClassified());

        itemsList.splice(placeholderPosition, 0, ItemPlaceholder);
    }

    if (isFetching) {
        itemsList.push(loadingItems);
    }
    const showListingBP = marketConfig.get('olxAutos', 'showListingBP');

    if (brandPromisePosition && showListingBP && (!similarAdsCount || isNewUspVasDesign)) {
        const user = {
            dealer: {
                categories: [
                    {
                        dealer_type: USER_TYPE.COCO,
                        id: categoryID
                    }
                ]
            }
        };

        brandPromisePosition = isNewUspVasDesign && !(showExchangeCard && !similarAdsCount && items?.length >= 6) ? brandPromisePosition + 1 : brandPromisePosition;
        const BrandPromiseElem = <BrandPromise key={ `bp_${brandPromisePosition}` } user={ user } isNewUspVasDesign={ isNewUspVasDesign } onVasTagTap={ isNewUspVasDesign && onVasTagTap } categoryId={ categoryID } isListingPage />;

        itemsList.splice(brandPromisePosition, 0, BrandPromiseElem);
    }

    return itemsList;
};

ItemsList.propTypes = {
    items: PropTypes.array,
    isFetching: PropTypes.bool,
    showStatistics: PropTypes.bool,
    type: PropTypes.string,
    enabledRealImpressions: PropTypes.bool,
    onViewItem: PropTypes.func,
    isAdSuggested: PropTypes.bool,
    visualizationType: PropTypes.string,
    itemPostPlaceholderOrigin: PropTypes.string,
    itemPostPlaceholderPosition: PropTypes.func,
    sectionsItems: PropTypes.object,
    listingType: PropTypes.string,
    getListingDescription: PropTypes.func,
    showInspectionTag: PropTypes.bool,
    filters: PropTypes.object,
    onItemClick: PropTypes.func,
    source: PropTypes.string,
    sectionsOffset: PropTypes.array,
    locationId: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number
    ]),
    marketConfig: PropTypes.object,
    loadingItems: PropTypes.array,
    isListingPage: PropTypes.bool,
    onVasTagTap: PropTypes.func,
    openComparePopup: PropTypes.func,
    showPlaceholder: PropTypes.bool,
    appliedFiltersLength: PropTypes.number,
    similarAdsData: PropTypes.array,
    shouldRenderZRPWidgets: PropTypes.bool,
    isNewUspVasDesign: PropTypes.bool,
    similarAdsCount: PropTypes.number,
    categoryID: PropTypes.string,
    listingBodyType: PropTypes.string,
    showSponseredTag: PropTypes.bool,
    showDealerTag: PropTypes.bool,
    fromPage: PropTypes.string,
    isDealerPreview: PropTypes.bool,
    isDealerProfile: PropTypes.bool,
    location: PropTypes.shape({
        query: PropTypes.shape({
            page: PropTypes.number
        })
    })
};

export default React.memo(ItemsList);
