import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import { connect } from 'react-redux';
import withRouter from 'HOCs/withRouter';
import { Helmet } from 'react-helmet-async';
import withConfig from 'HOCs/withConfig/withConfig';
import { urlJoin } from 'Helpers/url';
import { decodeFilters, encodeFilters } from 'Helpers/filters';
import { getCanonical } from 'Selectors/canonicals';
import { MAIN_CATEGORY } from 'Constants/categories';
import { shouldNotIndexPriceRange } from 'Helpers/numbers';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';
import { DECIMAL_RADIX } from 'Constants/items';

function rewriteCanonicalCategory(pathname, rewriteCategories) {
    const cagtegoryRegex = /([\w\d-]*_c([\d]+))/;
    const match = cagtegoryRegex.exec(pathname);

    if (match && match[2] && rewriteCategories[match[2]]) {
        const newPathname = pathname.replace(match[0], rewriteCategories[match[2]]);

        return newPathname;
    }

    return pathname;
}

function getCanonicalURL(host, config, location) {
    const basename = location.basename ? location.basename.toLowerCase() : '';
    const { canonicalRewriteCategories } = config.get('SEO');

    let href = urlJoin(host, basename, canonicalRewriteCategories ? rewriteCanonicalCategory(location.pathname, canonicalRewriteCategories) : location.pathname);

    if (location.query) {
        const query = [];

        if (location.query.filter) {
            const filters = decodeFilters(location.query.filter);
            const singleFilters = Object.keys(filters)
                .reduce((singleFilters, key) => {
                    if (!Array.isArray(filters[key])) {
                        singleFilters[key] = filters[key];
                    }

                    return singleFilters;
                }, {});

            if (singleFilters && Object.keys(singleFilters).length) {
                query.push(`filter=${encodeURIComponent(encodeFilters(singleFilters))}`);
            }
        }

        if (location.query.page) {
            query.push(`page=${encodeURIComponent(location.query.page)}`);
        }

        if (query.length) {
            href += `?${query.join('&')}`;
        }
    }

    return href;
}

function shouldNoIndexByCategory(config, category) {
    const { noindexListingCategories } = config.get('SEO');

    return noindexListingCategories?.includes(category?.id);
}

function shouldNoIndexByFilter(filters, category = {}, config, sessionFeatures = []) {
    if (!filters) {
        return false;
    }
    const { indexableFilters } = config.get('SEO');
    const seoFlag = sessionFeatures?.includes('olxin-4436');

    const allowedFilters = indexableFilters[category.id] || [];
    const appliedFilters = decodeFilters(filters);

    if (seoFlag && category?.id === MAIN_CATEGORY.CARS) {
        for (const filter in appliedFilters) {
            const isFilterAllowed = allowedFilters.includes(filter);
            const filterValue = appliedFilters[filter];

            if (
                filter === 'price'
                && isFilterAllowed
                && (filterValue?.min || filterValue?.max)
                && !shouldNotIndexPriceRange({ min: parseInt(filterValue?.min || 0, DECIMAL_RADIX), max: parseInt(filterValue?.max, DECIMAL_RADIX) })
            ) {
                return true;
            }
        }
    }

    for (const filter in appliedFilters) {
        if (!allowedFilters.includes(filter)) {
            return true;
        }
    }

    // If there are multiple filters applied or any filter has multiple values, we don't want to index the listing page.
    return Object.keys(appliedFilters).length > 1 || Object.values(appliedFilters).some(val => Array.isArray(val) && val.length > 1);
}

function shouldNoIndexByItemThreshold(config, numItems) {
    const { noindexListingThreshold } = config.get('SEO');

    return numItems >= 0 && noindexListingThreshold && numItems <= noindexListingThreshold;
}

function shouldNoIndexByUrl(location = {}, params = {}) {
    return location?.query?.page && params.text;
}

function getNoIndex(config, location, numItems, category, params, sessionFeatures) {
    return shouldNoIndexByCategory(config, category)
        || shouldNoIndexByFilter(location?.query?.filter, category, config, sessionFeatures)
        || shouldNoIndexByItemThreshold(config, numItems)
        || shouldNoIndexByUrl(location, params);
}

function getCanonicalLink(config, location, canonical, items, category, host, seoCanonical) {
    const { canonicalRewriteCategories } = config.get('SEO');

    if (seoCanonical) {
        return <link rel="canonical" href={ `${host}${seoCanonical}` } />;
    }

    if (location && !getNoIndex(config, location, items, category)) {
        return <link rel="canonical" href={ canonical || getCanonicalURL(host, config, location) } />;
    }

    if (category?.id && canonicalRewriteCategories?.[category.id]) {
        return <link rel="canonical" href={ canonical || getCanonicalURL(host, config, location) } />;
    }

    return null;
}

export const Head = ({ config, items, location, canonical, category, seoCanonical, params, sessionFeatures }) => {
    const host = config.get('host');
    const noindex = getNoIndex(config, location, items, category, params, sessionFeatures || []);
    const canonicalLink = getCanonicalLink(config, location, canonical, items, category, host, seoCanonical);

    return (
        <Helmet>
            {noindex && <meta name="robots" content="noindex,nofollow" />}
            {canonicalLink}
        </Helmet>
    );
};

Head.propTypes = {
    config: PropTypes.shape({
        get: PropTypes.func.isRequired
    }).isRequired,
    items: PropTypes.number,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
        query: PropTypes.object
    }).isRequired,
    params: PropTypes.object,
    canonical: PropTypes.string,
    category: PropTypes.object,
    seoCanonical: PropTypes.string,
    sessionFeatures: PropTypes.arrayOf(PropTypes.string)
};

Head.defaultProps = {
    items: -1
};

const mapStateToProps = (state, props) => ({
    canonical: getCanonical(state, props)
});

export default compose(
    withConfig,
    withRouter,
    withSessionFeature,
    connect(mapStateToProps)
)(Head);
