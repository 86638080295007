import React from 'react';
import PropTypes from 'prop-types';
import { PREFIX_AUT_ID } from './constants';
import ItemLocation from './ItemLocation';
import css from './LocationGroup.scss';
import classnames from 'classnames';
import LastItemGroup from './LastItemGroup';
import { css as uiCss } from 'panamera-react-ui';
const { customScrollbar: extraCss } = uiCss;

const LocationGroup = ({ items, onSelect, level, onViewAll, isFiltersV2, category }) => {
    const item = !!items.length && items[0];
    const isLastLocation = item && item.list && item.list.length > 1;
    const classList = classnames(css.locationGroup, css[`locationGroupLevel${level}`],
        {
            [extraCss.customScrollbar]: level === 0 && isFiltersV2,
            [css.tanakLocationGroup]: level === 0 && isFiltersV2
        });
    const childProps = { items: item.list, onSelect, onViewAll, level: level + 1, isFiltersV2, category };

    return item && (
        <ul className={ classList } data-aut-id={ `${PREFIX_AUT_ID}${level}` }>
            <li>
                <ItemLocation item={ item } onClick={ onSelect } isFiltersV2={ isFiltersV2 } category={ category } />
                { isLastLocation ? <LastItemGroup { ...childProps } />
                    : <LocationGroup { ...childProps } /> }
            </li>
        </ul>
    );
};

LocationGroup.propTypes = {
    items: PropTypes.array,
    onSelect: PropTypes.func.isRequired,
    onViewAll: PropTypes.func,
    level: PropTypes.number,
    isFiltersV2: PropTypes.bool,
    category: PropTypes.string
};

LocationGroup.defaultProps = {
    items: [],
    level: 0,
    onViewAll: () => {}
};

export default LocationGroup;
