/* eslint-disable react/prefer-stateless-function, no-unused-vars */

import React from 'react';
import hoistNonReactStatic from 'hoist-non-react-statics';

import { getPayloadForFAQ, getFaqDynamicValues } from 'Components/FAQ/FAQ.helpers';

import { getFaq } from 'Actions/faq';

const withFaq = WrappedComponent => {
    const configWrapper = c => ({
        get: (...args) => args.reduce((v = {}, k) => v[k], c)
    });

    class withFaq extends React.PureComponent {
        render() {
            return <WrappedComponent { ...this.props } />;
        }
    }

    withFaq.fetchData = async (...args) => {
        const [dispatch, renderProps, req, { config }, store] = args;

        const promises = [
            (WrappedComponent.fetchData ? WrappedComponent.fetchData(...args) : Promise.resolve())
        ];

        return Promise.all(promises).then(() => {
            const state = store.getState();

            const result = getPayloadForFAQ(state, renderProps, configWrapper(config), req.sessionFeatures);
            const payload = getFaqDynamicValues(result.category, result.parentCategoryId, { ...result, params: renderProps.params, sessionFeatures: req.sessionFeatures });

            // Will trigger api call when feature flag is enabled.
            if (payload.type) {
                return dispatch(getFaq(result?.category?.id, payload.type, payload.content, req.sessionFeatures));
            }

            return Promise.resolve();
        });
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    withFaq.displayName = `withFaq(${wrappedComponentName})`;

    return hoistNonReactStatic(withFaq, WrappedComponent, { fetchData: true });
};

export default withFaq;
