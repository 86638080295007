/* eslint-disable react/prefer-stateless-function, no-unused-vars */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import hoistNonReactStatic from 'hoist-non-react-statics';
import { compose } from 'redux';

import { fetchDynamicContent } from 'Actions/dynamicContent';
import { getDynamicContentUrl } from 'Helpers/dynamicContent';
import { MAIN_CATEGORY, MAIN_CATEGORY_V1 } from 'Constants/categories';
import withSessionFeature from 'HOCs/withSessionFeature/withSessionFeature';

const { ACCESSORIES, BIKES, BICYCLES, CARS, JOBS, MOBILES, MOBILE_PHONES, MOTORCYCLES, REAL_ESTATE, SCOOTERS, TABLETS } = MAIN_CATEGORY;
const {
    TV_AND_AUDIO,
    KITCHEN_OTHER_APPLIANCES,
    COMPUTER_LAPTOPS,
    CAMERA_LENSES,
    GAMES_ENTERTAINMENT,
    FRIDGE,
    SOFA_DINNING,
    BEDS_WARDROBES,
    HOME_DECOR_GARDEN,
    KIDS_FURNITURE,
    OTHER_HOUSEHOLD,
    MEN_FASHION,
    WOMEN_FASHION,
    KIDS_FASHION
} = MAIN_CATEGORY_V1;
const getCategories = () => ([
    ACCESSORIES,
    BIKES,
    BICYCLES,
    CARS,
    JOBS,
    MOBILES,
    MOBILE_PHONES,
    MOTORCYCLES,
    REAL_ESTATE,
    SCOOTERS,
    TABLETS,
    TV_AND_AUDIO,
    KITCHEN_OTHER_APPLIANCES,
    COMPUTER_LAPTOPS,
    CAMERA_LENSES,
    GAMES_ENTERTAINMENT,
    FRIDGE,
    SOFA_DINNING,
    BEDS_WARDROBES,
    HOME_DECOR_GARDEN,
    KIDS_FURNITURE,
    OTHER_HOUSEHOLD,
    MEN_FASHION,
    WOMEN_FASHION,
    KIDS_FASHION
]);

const withDynamicContent = WrappedComponent => {
    class WithDynamicContent extends React.PureComponent {
        componentDidMount() {
            const { dispatch, location, params, ...state } = this.props;
            const categories = getCategories();

            if (categories.includes(params.categoryID)) {
                const url = getDynamicContentUrl(state, location.query, params);

                if (url) {
                    dispatch(fetchDynamicContent({ url }));
                }
            }
        }

        componentDidUpdate(prevProps) {
            const { dispatch, location, params, ...state } = this.props;
            const url = getDynamicContentUrl(state, location.query, params);
            const shouldFetch = prevProps.location !== location;
            const categories = getCategories();

            if (shouldFetch && url && categories.includes(params.categoryID)) {
                dispatch(fetchDynamicContent({ url }));
            }
        }

        render() {
            return <WrappedComponent { ...this.props } />;
        }
    }

    WithDynamicContent.propTypes = {
        api: PropTypes.object.isRequired,
        dispatch: PropTypes.func.isRequired,
        filters: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        locations: PropTypes.object.isRequired,
        params: PropTypes.object.isRequired
    };

    WithDynamicContent.defaultProps = {
        api: {},
        dispatch: () => {},
        filters: {},
        location: {
            query: {}
        },
        locations: {},
        params: {}
    };

    WithDynamicContent.fetchData = async (...args) => {
        const [dispatch, renderProps, _, __, store] = args;

        const { location, params } = renderProps;

        const promises = [
            (WrappedComponent.fetchData ? WrappedComponent.fetchData(...args) : Promise.resolve())
        ];

        return Promise.all(promises).then(() => {
            const state = store.getState();
            const url = getDynamicContentUrl(state, location.query, params);
            const categories = getCategories();

            if (categories.includes(params.categoryID) && url) {
                return dispatch(fetchDynamicContent({ url }));
            }

            return Promise.resolve();
        });
    };

    const wrappedComponentName = WrappedComponent.displayName || WrappedComponent.name || 'Component';

    WithDynamicContent.displayName = `withDynamicContent(${wrappedComponentName})`;

    const mapStateToProps = state => ({
        api: state.api,
        filters: state.filters,
        locations: state.locations
    });

    const connectedComponent = compose(withSessionFeature, connect(mapStateToProps))(WithDynamicContent);

    return hoistNonReactStatic(connectedComponent, WrappedComponent, { fetchData: true });
};

export default withDynamicContent;
